import React from "react";
import {
  Root,
  Title,
  SubHeading,
  StyledLink,
  BodyDiv,
} from "./TextLinks.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { Span } from "@/outscal-commons-frontend/Styled/TextStyles";

const TextLinksView = ({
  name,
  heading,
  subHeading,
  listData,
  headingAlignment,
}) => {
  return (
    <Root alignment={headingAlignment}>
      {heading && (
        <Title
          headingAlignment={headingAlignment}
          fontSize="xxxl"
          fontWeight="midBold"
        >
          {heading}
        </Title>
      )}
      {subHeading && (
        <SubHeading
          headingAlignment={headingAlignment}
          color="textDarkGrey"
          fontSize="sm"
        >
          {subHeading}
        </SubHeading>
      )}
      <BodyDiv headingAlignment={headingAlignment}>
        {listData?.map((item, index) => (
          <Span key={index}>
            <StyledLink
              target="_blank"
              href={item?.link}
              data-analytics={[
                UIElements.LINK,
                `${name ? `${name}_${item?.slug}` : ""}`,
              ]}
            >
              {item?.name}{" "}
            </StyledLink>
            {index < listData.length - 1 && <Span padding="0 12px"> • </Span>}
          </Span>
        ))}
      </BodyDiv>
    </Root>
  );
};

export default TextLinksView;
