import React, { useState } from "react";
import TextLinksView from "./TextLinks.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import styles from "./TextLinks.module.css";

const TextLinks = ({
  name = "",
  heading = "",
  subHeading = "",
  listData = [],
  interLinks = {},
  headingAlignment = "center",
  showOn,
}) => {
  const { listData: interLinksListData = [] } = interLinks;
  const [showMore, setShowMore] = useState(false);
  return (
    <UseComponentVisibility conditions={showOn}>
      <div className={styles["txt-links-section-wrapper"]}>
        <section
          data-outscal-section
          className={`${styles["txt-links"]} ${
            showMore ? styles["show-more"] : ""
          }`}
        >
          <TextLinksView
            name={name}
            heading={heading}
            subHeading={subHeading}
            listData={[...listData, ...interLinksListData]}
            headingAlignment={headingAlignment}
          />
        </section>
        <button
          onClick={() => setShowMore(!showMore)}
          className={styles["show-more-toggle-btn"]}
        >
          {showMore ? "Hide" : "Show"}
        </button>
      </div>
    </UseComponentVisibility>
  );
};

export default TextLinks;
